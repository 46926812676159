import React from "react";
import { Helmet } from "react-helmet";
import "../Styles/PmHealthCare.css";
import NotesDrawer from "./NotesDrawer";

import { Button } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { markdownContent } from "./content/markdownContent";

const PmHealthcare = () => {
  const markdown = markdownContent.healthcare;
  return (
    <div>
      <div className="container">
        <div className="main-header-outer">
          <div className="header">
            <div className="header-logo">
              <img
                src="./webchat/pm-healthcare/images/hospital-logo.png"
                alt=""
              />
            </div>
            <div id="header-navbar">
              <div className="header-nav-links">
                <ul id="header-nav-links-ul">
                  <li>Home</li>
                  <li>About</li>
                  <li>Contact</li>
                  <li>Our Doctors</li>
                  <li>Services</li>
                </ul>
              </div>

              <div className="appoinment-button-section">
                <p className="appoinment-button">Book Appoinment</p>
              </div>
            </div>
            <div className="header-nav-buttons">
              <a href="/">
                <Button type="primary" className="home-redirect-button">
                  <HomeOutlined style={{ fontSize: "20px" }} />
                </Button>
              </a>
              <NotesDrawer markdown={markdown} />
            </div>
          </div>
        </div>

        <div className="body-content">
          <div className="banner-section">
            <img src="./webchat/pm-healthcare/images/doctor.png" alt="" />
          </div>

          <div className="hospital-welcome">
            <div className="welcome-content">
              <p className="welcome-message">
                Your Health Is <br /> <span> Our Priority</span>
              </p>
              <p className="welcome-message-service">24/7 AMBULANCE SERVICES</p>
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <script
          src="./webchat/pm-healthcare/pm-webchat.js"
          type="text/javascript"
        />
      </Helmet>
    </div>
  );
};

export default PmHealthcare;