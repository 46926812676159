import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Popover,
  PopoverHandler,
  PopoverContent,
  Button,
} from "@material-tailwind/react";

import { BiSolidDashboard } from "react-icons/bi";
import { RiRobot3Fill, RiSettings3Fill } from "react-icons/ri";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, NavLink, Route, Routes } from "react-router-dom";
import { PiCalendarBlankFill } from "react-icons/pi";
import { TbReportSearch } from "react-icons/tb";
import { HiDocumentReport } from "react-icons/hi";
import MedicalTranscription from "../DemoBots/MedicalTranscription";
import Dashboard from "./pages/Dashboard";
import AppointmentBot from "./pages/AppoinmentBot";
import ReportAnalyzer from "./pages/ReportAnalyzer";
import Settings from "./pages/Settings";
import { IoChevronBack } from "react-icons/io5";

function HealthcarePortal() {
  const [userDetails, setUserDetails] = useState();
  const [userInitial, setUserInitial] = useState("");
  const [selectedItem, setSelectedItem] = useState(""); // New state to store selected item
  const menuItem = [
    {
      path: "",
      name: "Dashboard",
      icon: <BiSolidDashboard size={35} />,
    },
    {
      path: "medical-transcription-bot",
      name: "Transcription Bot",
      icon: <RiRobot3Fill size={35} />,
    },
    {
      path: "appointment-bot",
      name: "Appointment Bot",
      icon: <PiCalendarBlankFill size={35} />,
    },
    {
      path: "report-analyzer",
      name: "Report Analyzer",
      icon: <HiDocumentReport size={35} />,
    },
    {
      path: "settings",
      name: "Settings",
      icon: <RiSettings3Fill size={35} />,
    },
  ];
  useEffect(() => {
    const sessionKey = sessionStorage.getItem("msal.account.keys");
    const data = JSON.parse(sessionKey);
    const sessionData = data[0];
    const userData = sessionStorage.getItem(sessionData);

    if (userData) {
      const parsedData = JSON.parse(userData).idTokenClaims;
      setUserDetails(parsedData);

      setUserInitial(
        parsedData.name
          .split(" ")
          .map((n) => n[0])
          .join("")
      );
    }
  }, []);

  const handleItemClick = (itemName) => {
    setSelectedItem(itemName); // Update selected item when clicked
  };

  return (
    <div className="bg-[#e4e8f4] h-full py-9 pl-4 flex">
      <div className="absolute top-1 right-10 font-bold text-[#343e64]">
        <Link to="/" className="flex items-center">
          <IoChevronBack />
          Home
        </Link>
      </div>
      <Card className="h-[calc(90vh)] w-full max-w-[20rem] shadow-xl shadow-blue-gray-900/5 bg-[#343e64] rounded-xl">
        <div className="container">
          <div className="flex flex-col gap-1 items-center p-4">
            <img src="/images/inextlabs-logo-white.png" alt="" width={150} />
            <Typography variant="p" className="text-[#fff] text-sm pb-2">
              inFlow Enterprise for Healthcare
            </Typography>
          </div>
          {menuItem.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              className="link"
              activeclassName="active"
            >
              <List>
                <ListItem
                  className={`text-[#A8A5E6] flex px-8 gap-4 py-4 font-semibold ${
                    selectedItem === item.name ? "text-[#fff]" : ""
                  }`} // Apply different background color if item is selected
                  onClick={() => handleItemClick(item.name)}
                >
                  <ListItemPrefix>{item.icon}</ListItemPrefix>
                  {item.name}
                </ListItem>
              </List>
            </Link>
          ))}
        </div>

        <div className="user-profile absolute bottom-10 px-8">
          {userInitial && (
            <div className="flex gap-4 items-center">
              <div className="user-profile-initials bg-[#D9D9D9] text-[#221F6D] rounded-full h-10 w-10 font-bold flex items-center justify-center">
                {userInitial}
              </div>
              <div className="flex flex-col text-[#fff] ">
                <p className="font-semibold">{userDetails.name}</p>
                <p className="font-light text-xs">{userDetails.emails[0]}</p>
              </div>
              <div>
                <Popover
                  animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0, y: 25 },
                  }}
                >
                  <PopoverHandler>
                    <Button className="p-0 m-0">
                      <BsThreeDotsVertical className="text-[#fff]" size={20} />
                    </Button>
                  </PopoverHandler>
                  <PopoverContent className="p-2 bg-[#fff]">
                    <Button
                      className="p-0 text-sm text-[#221F6D]"
                      onClick={() => {
                        sessionStorage.clear();
                        window.location.reload();
                      }}
                    >
                      Log out
                    </Button>
                  </PopoverContent>
                </Popover>
              </div>
            </div>
          )}
        </div>
      </Card>
      <div className="w-9/12 h-[calc(90vh)] bg-[#fff] mx-auto rounded-xl overflow-y-scroll">
        <Routes>
          <Route path="" exact element={<Dashboard />} />
          <Route
            path="medical-transcription-bot"
            element={<MedicalTranscription />}
          />
          <Route path="settings" element={<Settings />} />
          <Route path="appointment-bot" element={<AppointmentBot />} />
          <Route path="report-analyzer" element={<ReportAnalyzer />} />
        </Routes>
      </div>
    </div>
  );
}

export default HealthcarePortal;
