import React, { useEffect, useRef, useState } from "react";
import {
  AliwangwangOutlined,
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button } from "antd";

import "./styles/supremecourt.css";
import NotesDrawer from "../NotesDrawer";

import { markdownContent } from "../content/markdownContent";

const markdown = markdownContent.bank;
const buttonColor = "#2d2d2d";

function SupremeCourtDataAssistant() {
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageHistory, setMessageHistory] = useState([]);

  useEffect(() => {
    const scrollChatToBottom = () => {
      const chatWindow = document.querySelector(".supreme-court-chat-messages");
      if (chatWindow) {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      }
    };

    scrollChatToBottom();
  }, [messageHistory]);

  const inputRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    const userMessage = searchQuery.trim();
    if (userMessage === "") {
      return;
    }

    setMessageHistory((prevHistory) => [
      ...prevHistory,
      { userMessage: userMessage, aiResponse: null},
    ]);

    handleSearch(userMessage);
  };

  const handleSearch = async (userMessage) => {
    setLoading(true);
    inputRef.current.value = "";
    try {
      const response = await fetch(
        "https://inextlabs-gpt-vector-services-as.azurewebsites.net/vector-search",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "search-api-key":
              "7R529w8mMMEEr3uC6Q3drfCbhbC27uk3uAL4HiigI9AzSeBeM0l6",
            "openai-api-key": "0a3f454aa46746b5afc360b75d5265ca",
            "conversation-id": "courtcase123",
            "client-id": "INEXT123",
          },
          body: JSON.stringify({
            search_name: "bots-common-search",
            index_name: "supreme-court-vector-index",
            openai_service_name: "inextlabs-legal-demo-oai",
            openai_embeddings_name: "text-embedding-ada-002",
            question: searchQuery,
            openai_completion_name: "gpt-35-turbo-16k",
            storage_account_connection_string: "DefaultEndpointsProtocol=https;AccountName=inextlabslegalstorage;AccountKey=oXb4+QIGejqKjS0I4uAzsu38sBLTICSMsE/QWvecC6dw37yte5CzUFkkucgBhw+XVsx01HxIMjWd+AStiUbE9g==;EndpointSuffix=core.windows.net",
            container_name: "supreme-court-vector-datasource",
            system_prompt:
              "You are a helpful assistant to get the information extract from the form such as Case Number, Case Title, Branch, Defendant details.  Please provide each information with at the end.its important to remember to provide the file URL. \n\nContext:\n",
            temperature: 0,
            max_tokens: 400,
            top_p: 1,
            past_message_count: 5,
            search_response_count: 5,
          }),
        }
      );

      if (response.status === 500) {
        updateAiResponse(
          userMessage,
          "An internal server error occurred. Please try again later."
        );
      } else {
        const data = await response.json();
        updateAiResponse(userMessage, data.answer);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      updateAiResponse(
        userMessage,
        "An error occurred while processing your request."
      );
    } finally {
      setLoading(false);
    }
  };

  const updateAiResponse = (userMessage, aiResponse) => {
    setMessageHistory((prevHistory) => {
      return prevHistory.map((message) =>
        message.userMessage === userMessage
          ? { ...message, aiResponse }
          : message
      );
    });
  };

  return (
    <div className="supreme-court-chat-container">
      <div className="supreme-court-chat-header">
        <div className="supreme-court-chat-header-logo">
          <img src="\images\inextlabs-logo-white.png" alt="" width="170" />
        </div>
        <div className="supreme-court-chat-header-title"></div>
        <div className="supreme-court-chat-header-nav-buttons">
          <a href="/">
            <Button
              type="primary"
              style={{ backgroundColor: "#2d2d2d" }}
              className="home-redirect-button"
            >
              <HomeOutlined style={{ fontSize: "20px" }} />
            </Button>
          </a>
          <NotesDrawer markdown={markdown} buttonColor={buttonColor} />
        </div>
      </div>
      <div className="supreme-court-chat-window-container">
        <div className="supreme-court-chat-window-sidebar">
          <div className="supreme-court-chat-window-sidebar-image">
            <img src="/images/demo-images/judges.jpg" alt="" width={330} />
          </div>
          <div className="supreme-court-chat-window-sidebar-title">
            <h2> Supreme Court Data Assistant</h2>
          </div>
        </div>
        <div className="supreme-court-chat-window">
          <div className="supreme-court-chat-messages">
            {messageHistory.map((message, index) => (
              <div key={index} className="message">
                <div className="supreme-court-chat-messages-question">
                  <div className="message-content">{message.userMessage}</div>
                  <div className="user-avatar">
                    <Avatar icon={<UserOutlined />} />
                  </div>
                </div>
                {message.aiResponse && (
                  <div className="supreme-court-chat-messages-answer">
                    <div className="system-icon">
                      <Avatar icon={<AliwangwangOutlined />} />
                    </div>
                    <div className="message-content">{message.aiResponse}</div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <form onSubmit={handleSubmit} className="supreme-court-chat-form">
            <input
              type="text"
              id="message"
              name="message"
              ref={inputRef}
              required
              placeholder="Type your message"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button
              type="submit"
              onClick={handleSubmit}
              className={`supreme-court-chat-form-submit-button ${
                loading ? "hide" : ""
              }`}
              id="send-button"
            >
              <img src="\images\send-black.svg" alt="" width="25" />
            </button>
            {loading && (
              <div className="supreme-court-loading">
                <div id="supreme-court-loading-spinner" className="supreme-court-custom-loader"></div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default SupremeCourtDataAssistant;
