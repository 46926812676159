import React, { useState, useRef } from "react";
import { HomeOutlined } from "@ant-design/icons";
import { Button } from "antd";

import "./styles/phonepeassist.css";
import NotesDrawer from "../NotesDrawer";

import { markdownContent } from "../content/markdownContent";
import { Link } from "react-router-dom";
const markdown = markdownContent.bank;
const buttonColor = "#480e89";

const PhonepeAssist = () => {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [submittedQuestion, setSubmittedQuestion] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [finished, setFinished] = useState(false);

  const inputRef = useRef(null);

  const handleSearch = async () => {
    setLoading(true);
    inputRef.current.value = "";
    try {
      const response = await fetch(
        "https://phone-pe-as.azurewebsites.net/vector-search",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "search-api-key":
              "hgUur7nc5A6dxVCevdfsPa9h8CabIy87L7vHCbiSu3AzSeAADwNV",
            "openai-api-key": "c81b74fa641743a899aac5f58dd0a338",
            "conversation-id": "cfe",
            "client-id": "INEXT123",
            Cookie: "session = 2e2def9d-9a50-47d7-9fb2-b72feeb6f7a0",
          },
          body: JSON.stringify({
            search_name: "abdul-search",
            index_name: "phonepe-vector-data-index",
            openai_service_name: "abdul",
            openai_embeddings_name: "text-embedding-ada-002",
            question: searchQuery,
            openai_completion_name: "text-davinci-003",
            system_prompt:
              "As an AI assistant specialized in Data Analytics, your role is to:Carefully read and comprehend the provided content.Determine if the user'''s query is related to the given content.Analyze the user'''s question and provide an answer that fits one of the following categories: comparison, summarization, explanation, classification, or analytics. you must ensure that fun facts and url of the page are only derived from the provided content and not generated by the AI itself. Remember, only one fun fact and url of the page should be given at a time, and it should not be repeated for the same user. if possible give one relevant fun fact and url of the page along with the answer.In case the user'''s query involves mathematical calculations, carefully verify the accuracy of your calculations before providing the answer. Before responding, take a moment to think and ensure your answer aligns with these guidelines. Your primary goal is to provide accurate and relevant information based solely on the provided content.Address the question fully and ensure accuracy in your responses. if you don'''t have information but you have fun-fact about that state and url just give fun fact and url. If it'''s unrelated, respond with the exact phrase '''I don'''t have that information'''. Provide your response as a TEXT with the following schema: Structure: ``` answer \n\n Fun fact \n\n url```.Ensure that your responses are strictly based on the given content, without adding any external information or context.Ensure the state in question and proper solution in answer.content:\n\n",
            temperature: 0,
            max_tokens: 400,
            top_p: 1,
            past_message_count: 0,
          }),
        }
      );

      if (response.status === 500) {
        setSearchResults("An internal server error occurred. Please try again later.");
      } else {
        const data = await response.json();
        setSearchResults(data.answer);
      }
    } catch (error) {
      setSearchResults("An error occurred.");
    } finally {
      setLoading(false);
      setFinished(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userQuestion = searchQuery.trim();
    if (userQuestion !== "") {
      setSubmittedQuestion(userQuestion);
      handleSearch();
    }
    setFinished(false);
  };

  return (
    <div className="phonepe-pulse-chat-container">
      <div className="phonepe-pulse-chat-header">
        <div className="phonepe-pulse-chat-header-logo">
          <img src="\images\inextlabs-logo-white.png" alt="" width="170" />
        </div>
        <div className="phonepe-pulse-chat-header-title">
          <h1> Phonepe Pulse</h1>
        </div>
        <div className="phonepe-pulse-chat-header-nav-buttons">
          <a href="/">
            <Button
              type="primary"
              style={{ backgroundColor: "#480e89" }}
              className="home-redirect-button"
            >
              <HomeOutlined style={{ fontSize: "20px" }} />
            </Button>
          </a>
          <NotesDrawer buttonColor={buttonColor} />
        </div>
      </div>

      <div className="phonepe-pulse-chat-window">
        <div className="phonepe-pulse-chat-form-container">
          <form onSubmit={handleSubmit} className="phonepe-pulse-chat-form">
            <input
              ref={inputRef}
              type="text"
              id="message"
              name="message"
              className="phonepe-input-field"
              required
              placeholder="Type your message"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button
              className={`phonepe-pulse-chat-form-submit-button `}
              id="send-button"
              type="submit"
            >
              <img
                src="/images/demo-images/send-button.svg"
                alt=""
                width="70"
              />
            </button>
          </form>
        </div>

        <div className="phonepe-pulse-chat-messages">
          {finished && (
            <div className="phonepe-pulse-chat-messages-response">
              <div className="user-question">{submittedQuestion}</div>
              <div className="response">{searchResults}</div>
            </div>
          )}
          {loading && (
            <div className="loading">
              <div id="loading-spinner" className="phonepe-custom-loader"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PhonepeAssist;
