import React from "react";
import "../Styles/RetailCycle.css";
import { Helmet } from "react-helmet";
import { Button } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import NotesDrawer from "./NotesDrawer";
import { markdownContent } from "./content/markdownContent";
const RetailCycle = () => {
  const markdown = markdownContent.retail;
  return (
    <>
      <div className="cycle-mart-container">
        <div className="cycle-mart-main-header">
          <div className="cycle-mart-main-header-nav-buttons">
            <a href="/">
              <Button type="primary" className="home-redirect-button">
                <HomeOutlined style={{ fontSize: "20px" }} />
              </Button>
            </a>
            <NotesDrawer markdown={markdown} />
          </div>
          <div className="cycle-mart-header">
            <div className="cycle-mart-header-logo">
              <img src="./webchat/inextlabs-faq-demo/images/logopos.png" alt="" />
            </div>

            <div className="cycle-mart-header-nav-links">
              <ul>
                <li>Home</li>
                <li>About</li>
                <li>Contact</li>
                <li>Careers</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="cycle-mart-body-content">
          <div className="cycle-mart-banner-section">
            {/* <img src="./webchat/inextlabs-faq-demo/images/banner.png" alt="" /> */}
          </div>
          <div className="cycle-mart-offers-section">
            <div className="cycle-mart-offer">
              <p className="cycle-mart-model">SmartPOS 2000 Series</p>
              <h1 className="cycle-mart-title">
                Revolutionizing Point of Sale
              </h1>
              <p className="cycle-mart-offers-discount">
                {" "}
                <span>special offer:</span> Get 10% off on your first POS system
              </p>
              <button className="cycle-mart-explore-button">
                Explore POS Systems
              </button>
            </div>
          </div>
        </div>

        {/* <div className="cycle-mart-footer">
          <p>Powered by iNextLabs</p>
        </div> */}
      </div>
      <Helmet>
        <script
          src="./webchat/inextlabs-faq-demo/webchat-FAQ.js"
          type="text/javascript"
        />
      </Helmet>
    </>
  );
};

export default RetailCycle;
