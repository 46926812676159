import { HomeOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

const OutBoundCallBot = () => {
  return (
    <div>
      <div
        className="electo-bot-header"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "30px",
        }}
      >
        <a href="/">
          <Button
            type="primary"
            className="home-redirect-button"
            style={{ backgroundColor: "#f05742" }}
          >
            <HomeOutlined style={{ fontSize: "20px" }} />
          </Button>
        </a>
      </div>
      <iframe
        src="https://demobotscommonstorage.blob.core.windows.net/call-automation-outbound-call/index.html"
        className="w-full h-[90vh] border-none"
        title="HTML File"
      ></iframe>
    </div>
  );
};

export default OutBoundCallBot;
