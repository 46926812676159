import React from "react";
import "../Styles/Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="partners-demo-footer-container">
        <div className="partners-demo-footer-inner-container">
          <div className="partners-demo-footer-left">
            Copyright © 2024 iNextLabs - All Rights Reserved
          </div>
          <div className="partners-demo-footer-right">
            <ul class="partners-demo-footer-social-media-wrapper">
              <li class="icon partners-demo-footer-social-media-facebook">
                <Link to="https://www.facebook.com/iNextLabs" target="blank">
                  <i class="fab fa-facebook-f"></i>
                </Link>
              </li>
              <li class="icon partners-demo-footer-social-media-twitter" target="blank">
                <Link to="https://twitter.com/inextlabs">
                  <i class="fab fa-twitter"></i>
                </Link>
              </li>
              <li class="icon partners-demo-footer-social-media-instagram" target="blank">
                <Link to="https://www.instagram.com/inextlabs/">
                  <i class="fab fa-instagram"></i>
                </Link>
              </li>

              <li class="icon partners-demo-footer-social-media-youtube" target="blank">
                <Link to="https://www.youtube.com/channel/UCJDvWr__3VODxszufzBUHcw">
                  <i class="fab fa-youtube"></i>
                </Link>
              </li>
            </ul>
            <p>info@inextlabs.com</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
