import React, { useEffect, useState } from "react";
import "../Styles/Solutions.css";
import { Link } from "react-router-dom";
import { appInsights } from "../AppInsights";
import { BsFillDiagram3Fill, BsRobot } from "react-icons/bs";
import { MdPlayCircleOutline } from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";
import { IoClose } from "react-icons/io5";

const Solutions = (props) => {
  const headerContent = props.content[0];

  const [userData, setUserData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDiagram, setShowDiagram] = useState(false);
  const [diagramImage, setDiagramImage] = useState("");

  useEffect(() => {
    const sessionKey = sessionStorage.getItem("msal.account.keys");
    const data = JSON.parse(sessionKey);
    const sessionData = data[0];
    const userData = sessionStorage.getItem(sessionData);

    if (userData) {
      const parsedData = JSON.parse(userData).idTokenClaims;
      setUserData(parsedData);
    }
  }, []);

  const trackLinkClick = (title) => {
    appInsights.trackEvent({
      name: title + " opened",
      properties: {
        title,
        email: userData.emails[0],
        user: userData.name,
      },
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const toggleDiagram = (image) => {
    setShowDiagram(!showDiagram);
    setDiagramImage(image); // Assuming you have a state to hold the image URL
  };
  const closeDiagram = () => {
    setShowDiagram(false);
  };
  return (
    <>
      <div className="partner-demo-solution-outer-container">
        <div className="partner-demo-solution-inner-container">
          {headerContent.type === "enterprise" && (
            <div className="partner-demo-solution-left-container">
              <img src={headerContent.headerImage} alt="Header" />
            </div>
          )}
          <div className="partner-demo-solution-right-container">
            <div className="partner-demo-solution-heading flex flex-col">
              <div className="w-full flex justify-between">
                <div>
                  {headerContent.icon}
                  <h4>{headerContent.title}</h4>
                </div>
              </div>
              <p>{headerContent.description}</p>
            </div>
            {headerContent.type === "enterprise" && (
              <div className="partner-demo-solution-left-asset">
                <button
                  onClick={() => toggleDiagram(headerContent.architectureImage)}
                >
                  <BsFillDiagram3Fill size={25} />
                  Architecture Diagram
                </button>
                <button>
                  <MdPlayCircleOutline size={25} />
                  Video
                  <span className="coming-soon">Coming Soon</span>
                </button>
              </div>
            )}
            <div className="partner-demo-solution-content">
              {props.content[1].map((content, index) => (
                <React.Fragment key={index}>
                  {content.status !== "coming soon" ? (
                    <Link
                      to={
                        content.urlType === "router"
                          ? content.url
                          : content.urlType === "link/internal"
                          ? `/launch-demo/${content.title}`
                              .toLowerCase()
                              .replace(/ /g, "-")
                          : content.url
                      }
                      rel="noreferrer"
                      target={content.urlType === "link/external" && "_blank"}
                      className="partner-demo-solution-content-card-link"
                      onClick={() => trackLinkClick(content.title)}
                    >
                      <div
                        className="partner-demo-solution-content-card"
                        style={{
                          height: props?.heightsize && props.heightsize,
                        }}
                      >
                        <div className="partner-demo-solution-content-card-header">
                          <div className="partner-demo-solution-content-card-image">
                            <img
                              src={content.image}
                              alt={content.title}
                              width={50}
                            />
                          </div>
                          {content.status && (
                            <div className="partner-demo-solution-content-card-status">
                              {content.status}
                            </div>
                          )}
                        </div>
                        <div className="partner-demo-solution-content-card-text flex flex-col gap-1">
                          <h3>{content.title}</h3>
                        </div>
                      </div>
                    </Link>
                  ) : (
                    <div
                      className="partner-demo-solution-content-card"
                      style={{
                        height: props?.heightsize && props.heightsize,
                      }}
                    >
                      <div className="partner-demo-solution-content-card-header">
                        <div className="partner-demo-solution-content-card-image">
                          <img
                            src={content.image}
                            alt={content.title}
                            width={50}
                          />
                        </div>
                        {content.status && (
                          <div className="partner-demo-solution-content-card-status">
                            {content.status}
                          </div>
                        )}
                      </div>
                      <div className="partner-demo-solution-content-card-text flex flex-col gap-1">
                        <h3>{content.title}</h3>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Framer Motion Animation for Architecture Diagram */}
      <AnimatePresence>
        {showDiagram && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="solution-partner-modal-overlay"
            onClick={toggleDiagram}
          >
            <motion.div
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              onClick={(e) => e.stopPropagation()}
              className="solution-partner-modal"
            >
              <h2>Architecture Diagram</h2>
              <img
                src={diagramImage}
                alt="Architecture Diagram"
                className="solution-partner-modal-img"
              />
              <button onClick={closeDiagram} className="absolute top-4 right-4">
                <IoClose size={25} />
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Solutions;
