import React from "react";
import { Link, useParams } from "react-router-dom";
import { ChannelContent } from "./content/ChannelContent";
import "../Styles/ScriptPage.css";
import { HomeOutlined } from "@ant-design/icons";
import { Button } from "antd";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const ScriptPage = () => {
  const { id } = useParams();
  const parsedContent = id;

  // Ensure arrays are defined and have elements at index 1
  const channelItems = ChannelContent.channel?.[1] || [];
  const industryItems = ChannelContent.industries?.[1] || [];
  const demoBotItems = ChannelContent.demoBots?.[1] || [];

  const allItems = [...channelItems, ...industryItems, ...demoBotItems];

  // Find the selected item
  const selectedItem = allItems.find(
    (item) => item.title.toLowerCase() === parsedContent.toLowerCase()
  );

  return (
    <>
      <div className="partners-demo-script-page-outer-container">
        <div className="partners-demo-script-page-inner-container">
          <div className="partners-demo-script-page-left">
            <div className="partners-demo-script-page-left-inner">
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              >
                {selectedItem?.scriptText}
              </ReactMarkdown>
            </div>
          </div>
          <div
            className="partners-demo-script-page-right"
            style={{
              backgroundImage: `url(${selectedItem?.background})`,
            }}
          >
            <Link to="/">
              <Button
                type="primary"
                className="home-redirect-button header-nav-btn"
              >
                <HomeOutlined style={{ fontSize: "20px" }} />
              </Button>
            </Link>
            <img
              src={selectedItem?.image}
              alt={selectedItem?.title}
              width={100}
            />
            <h2>{selectedItem?.title}</h2>
            <p>{selectedItem?.content}</p>
            <div className="partners-demo-script-page-right-inner-button">
              <Link
                to={selectedItem?.url}
                target="blank"
                rel="noreferrer"
                className="partners-demo-script-page-right-inner-button-link"
              >
                Launch Demo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScriptPage;