import React, { useEffect, useRef, useState } from "react";
import { Disclosure } from "@headlessui/react";
import ReactDOM from "react-dom";
import { Button } from "@material-tailwind/react";
import { ReactMic } from "react-mic";
import { MdMic } from "react-icons/md";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "../../Styles/MedicalTranscription.css";
import "react-quill/dist/quill.snow.css";
import { RiRobot3Fill } from "react-icons/ri";

const MedicalTranscription = () => {
  return (
    <div>
      <div className="flex m-4 justify-between">
        <h1 className="font-bold text-3xl text-[#1C1C1C]">Transcription Bot</h1>
        <RiRobot3Fill color="#221F6D" size={35} />
      </div>

      <MedicalTranscriptionBody />
    </div>
  );
};

export default MedicalTranscription;

const MedicalTranscriptionHeader = () => {
  return (
    <div>
      <Disclosure as="nav" className="bg-white-800">
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-8 w-auto"
                    src="/images/inextlabs-logo.png"
                    alt="Your Company"
                  />
                </div>
              </div>
              <div
                className="flex flex- items-center justify-center sm:items-stretch text-lg font-bold "
                color="blue-gray"
              >
                Medical Transcription Analysis
              </div>
              <div className="absolute inset-y-0  items-center gap-4 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* <Button variant="text" className="p-2 rounded-md">
                    Notes
                  </Button> */}
                <Link to="/">
                  <Button variant="outlined" className="p-2 rounded-md">
                    Home
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </>
      </Disclosure>
    </div>
  );
};

const MedicalTranscriptionBody = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [voice, setVoice] = useState(false);
  const [recordBlobLink, setRecordBlobLink] = useState(null);
  const [transcriptStart, setTranscriptStart] = useState(false);
  const [selectMedicalTerms, setSelectMedicalTerms] = useState([]);
  const [selectedAudio, setSelectedAudio] = useState(null); // State to hold the selected option
  const [audio, setAudio] = useState("");
  const [transcriptData, setTranscriptData] = useState("");
  const [loading, setLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [generateType, setGenerateType] = useState("");

  const handleSelectChange = (selectedAudio) => {
    setSelectedAudio(selectedAudio);
    if (selectedAudio.value === "Dr Steven") {
      setAudio(
        "https://inextlabswebassets.blob.core.windows.net/medical-transcript-audio/ttsmaker-file-2024-3-28-14-18-49.wav"
      );
    } else if (selectedAudio.value === "Dr Neerja") {
      setAudio(
        "https://inextlabswebassets.blob.core.windows.net/medical-transcript-audio/ttsmaker-file-2024-3-28-14-14-44.wav"
      );
    }
  };

  const onStop = (recordedBlob) => {
    setRecordBlobLink(recordedBlob);
    setIsRunning(false);
    setAudio(recordedBlob.blobURL);
  };

  const startHandle = async () => {
    setElapsedTime(0);
    setIsRunning(true);
    setVoice(true);
  };

  const stopHandle = () => {
    setIsRunning(false);
    setVoice(false);
  };

  const clearHandle = () => {
    setIsRunning(false);
    setVoice(false);
    setRecordBlobLink(false);
    setElapsedTime(0);
  };
  const submitAudio = () => {
    setLoading(true);
    // Function to fetch the audio file and send it as FormData
    function sendAudioAsFormData(url) {
      // Fetch the audio file
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a new FormData object
          const formData = new FormData();

          // Append the audio blob to the FormData object
          formData.append("file", blob, "audio.wav");
          const medicalTermsArray = selectMedicalTerms.map((term) =>
            term.value.trim()
          );

          // Append the medical terms array to the FormData object
          formData.append("medical_terms", JSON.stringify(medicalTermsArray));
          // Make a POST request with the FormData
          fetch(
            "https://medical-dictation-fa.azurewebsites.net/api/get-transcribe?code=TZGczCdjrGmlIN6JAB8fRj5F_JXB5s01btJeJopWOE7yAzFu8EbAUQ%3D%3D",
            {
              method: "POST",
              body: formData,
              redirect: "follow",
            }
          )
            .then((response) => response.json())
            .then((result) => {
              setTranscriptData(result.data);
              setLoading(false);
            })
            .catch((error) => {
              setTranscriptData(error.message);
              setLoading(false);
            });
        })
        .catch((error) => {
          setTranscriptData(error);
        });
    }

    // Call the function with the audio URL
    sendAudioAsFormData(audio);
  };

  const generateDocument = (reportType) => {
    setReportLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      content: transcriptData,
      reporttype: reportType,
    });

    setGenerateType(reportType);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://medical-dictation-fa.azurewebsites.net/api/generate-report?code=TZGczCdjrGmlIN6JAB8fRj5F_JXB5s01btJeJopWOE7yAzFu8EbAUQ%3D%3D",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setReportLoading(false);

        // Open new tab with MedicalTranscriptReport component
        const newTab = window.open("", "_blank");
        newTab.document.write(
          '<html><head><title>Medical Transcript Report</title><style>#reportContainer {width: 80%; margin: 5rem auto; font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI","Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; line-heigt:1.7rem;} } </style> </head><body><div id="reportContainer"></div></body></html>'
        );
        ReactDOM.render(
          <MedicalTranscriptReport transcriptReport={result.data} />,
          newTab.document.getElementById("reportContainer")
        );
      })
      .catch((error) => console.error(error));
  };

  const generateReport = () => {
    generateDocument("REPORT");
  };

  const generateLetter = () => {
    generateDocument("REFERRAL_LETTER");
  };

  return (
    <div className="flex bg-[url('https://demo.inextlabs.ai/images/6561.jpg')] bg-cover h-full bg-no-repeat py-20 rounded-xl">
      <div className="flex flex-col w-screen gap-8">
        <div className="flex w-auto items-center justify-center">
          <div className=" max-w-sm border py-8 px-6 mx-auto rounded-lg bg-[#fff]">
            <h2 className=" text-[22px] font-semibold flex items-center p-0">
              Dictate Audio <MdMic size={30} />
            </h2>

            <AudioTimer
              isRunning={isRunning}
              elapsedTime={elapsedTime}
              setElapsedTime={setElapsedTime}
            />

            <ReactMic
              record={voice}
              className="sound-wave w-full "
              visualSetting="sinewave"
              mimeType="audio/wav"
              onStop={onStop}
              strokeColor="#000000"
            />
            <div className="">
              {recordBlobLink ? (
                <Button
                  onClick={clearHandle}
                  className="text-[#343e64] font-medium text-[16px] "
                >
                  Clear
                </Button>
              ) : (
                ""
              )}
            </div>
            <div className="mb-3">
              {!voice ? (
                <Button
                  onClick={startHandle}
                  className=" bg-[#343e64] text-[#fff] rounded-md py-1 px-3 font-semibold text-[16px] "
                >
                  Start Recording
                </Button>
              ) : (
                <button
                  onClick={stopHandle}
                  className=" bg-[beige] text-[#f05742] rounded-md py-1 px-3 font-semibold text-[16px] "
                >
                  Stop Recording
                </button>
              )}
            </div>
            <div className="">
              {recordBlobLink?.blobURL || audio ? (
                <audio
                  controls
                  src={recordBlobLink?.blobURL || audio}
                  className="mx-0 mt-2 mb-4"
                />
              ) : null}
            </div>
            <p>Select Sample Audio</p>
            <div className="relative h-10 w-72 min-w-[200px]">
              <ReactSelect
                options={[
                  { value: "Dr Steven", label: "Dr Steven" },
                  { value: "Dr Neerja", label: "Dr Neerja" },
                ]}
                className="w-full mt-4"
                onChange={handleSelectChange} // Pass the handler function to onChange prop
                value={selectedAudio} // Pass the selected option to value prop
              />
            </div>
          </div>
        </div>
        <div className="flex w-auto flex-col gap-4 items-center justify-center">
          {!loading ? (
            <>
              <p className={transcriptStart === true ? "w-4/4" : "w-2/6"}>
                Highlight Medical Terms:
              </p>

              <div
                className={transcriptStart === true ? "" : "min-w-96 w-2/6"}
                style={transcriptStart === true ? { width: "350px" } : {}}
              >
                <MultiSelect
                  options={[
                    { value: "TreatmentName", label: "Treatment Name" },
                    { value: "MedicationName", label: "Medication Name" },
                    { value: "Diagnosis", label: "Diagnosis" },
                    { value: "SymptomOrSign", label: "Symptom Or Sign" },
                    {
                      value: "AdministrativeEvent",
                      label: "Administrative Event",
                    },
                    {
                      value: "HealthcareProfession",
                      label: "Healthcare Profession",
                    },
                    { value: "ExaminationName", label: "Examination Name" },
                    { value: "Course", label: "Course" },
                    { value: "Gender", label: "Gender" },
                    { value: "Age", label: "Age" },
                    { value: "Time", label: "Time" },
                    { value: "MeasurementValue", label: "Measurement Value" },
                    {
                      value: "ConditionQualifier",
                      label: "Condition Qualifier",
                    },
                    { value: "Ethnicity", label: "Ethnicity" },
                    { value: "Direction", label: "Direction" },
                    { value: "Frequency", label: "Frequency" },
                    { value: "MeasurementUnit", label: "Measurement Unit" },
                    {
                      value: "RelationalOperator",
                      label: "Relational Operator",
                    },
                    { value: "Variant", label: "Variant" },
                    { value: "GeneOrProtein", label: "Gene Or Protein" },
                    { value: "MutationType", label: "Mutation Type" },
                    { value: "Expression", label: "Expression" },
                    { value: "CareEnvironment", label: "Care Environment" },
                    { value: "ConditionScale", label: "Condition Scale" },
                    { value: "Dosage", label: "Dosage" },
                    { value: "MedicationForm", label: "Medication Form" },
                    { value: "MedicationRoute", label: "Medication Route" },
                    { value: "FamilyRelation", label: "Family Relation" },
                    { value: "Employment", label: "Employment" },
                    { value: "LivingStatus", label: "Living Status" },
                    { value: "SubstanceUse", label: "Substance Use" },
                    {
                      value: "SubstanceUseAmount",
                      label: "Substance Use Amount",
                    },
                    { value: "Allergen", label: "Allergen" },
                    { value: "Dose", label: "Dose" },
                    { value: "Date", label: "Date" },
                  ]}
                  value={selectMedicalTerms}
                  onChange={setSelectMedicalTerms}
                />
              </div>

              <div className="flex w-max gap-4">
                <Button
                  onClick={() => {
                    setTranscriptStart(true);
                    submitAudio();
                  }}
                  className="bg-[#343e64] text-[#fff] rounded-md py-2 px-8 font-semibold text-[18px]"
                >
                  Start
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="shape-loader"></div>
              <p className="font-semibold">
                Currently transcribing your content, this may take a moment.
              </p>
            </>
          )}
        </div>
      </div>
      {transcriptStart === true && !loading ? (
        <>
          <div className="w-screen flex flex-col gap-8 mx-8">
            <ReactQuill
              theme="snow"
              value={transcriptData}
              onChange={setTranscriptData}
            />
            {!reportLoading ? (
              <div className="flex gap-8">
                <Button
                  className="bg-[#343e64] text-[#fff] rounded-md py-2 px-8 font-semibold text-[15px] w-fit"
                  onClick={generateReport}
                >
                  Generate Discharge Summary
                </Button>
                <Button
                  className="bg-[#343e64] text-[#fff] rounded-md py-2 px-8 font-semibold text-[15px] w-fit"
                  onClick={generateLetter}
                >
                  Generate Refferal Letter
                </Button>
              </div>
            ) : (
              <div>
                <div className="shape-loader"></div>
                <p className="font-semibold">
                  {generateType === "REPORT"
                    ? "Generating your report, please wait while we compile the data."
                    : "Generating your letter, please wait while we compose the content."}
                </p>
              </div>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};

const AudioTimer = ({ isRunning, elapsedTime, setElapsedTime }) => {
  useEffect(() => {
    let intervalId;
    if (isRunning) {
      intervalId = setInterval(() => setElapsedTime(elapsedTime + 1), 10);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, elapsedTime]);

  const hours = Math.floor(elapsedTime / 360000);
  const minutes = Math.floor((elapsedTime % 360000) / 6000);
  const seconds = Math.floor((elapsedTime % 6000) / 100);
  const milliseconds = elapsedTime % 100;

  return (
    <div className=" text-[25px] mt-4 font-semibold ">
      <div className="time">
        {hours}:{minutes.toString().padStart(2, "0")}:
        <span className=" w-[23px] inline-block ">
          {" "}
          {seconds.toString().padStart(2, "0")}:
        </span>
        <span className=" w-[23px] inline-block ml-3">
          {milliseconds.toString().padStart(2, "0")}
        </span>
      </div>
    </div>
  );
};

const MedicalTranscriptReport = (props) => {
  return (
    <div>
      <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
        {props.transcriptReport}
      </ReactMarkdown>
    </div>
  );
};

const MultiSelect = (props) => {
  // isOptionSelected sees previous props.value after onChange
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const selectAllOption = {
    value: "<SELECT_ALL>",
    label: "All Terms",
  };

  const isSelectAllSelected = () =>
    valueRef.current.length === props.options.length;

  const isOptionSelected = (option) =>
    valueRef.current.some(({ value }) => value === option.value) ||
    isSelectAllSelected();

  const getOptions = () => [selectAllOption, ...props.options];

  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption] : props.value;

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    if (action === "select-option" && option.value === selectAllOption.value) {
      props.onChange(props.options, actionMeta);
    } else if (
      (action === "deselect-option" &&
        option.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      props.onChange(
        props.options.filter(({ value }) => value !== option.value),
        actionMeta
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };

  return (
    <ReactSelect
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      value={getValue()}
      onChange={onChange}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isMulti
    />
  );
};
