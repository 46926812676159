import React from "react";
import { Helmet } from "react-helmet";
import "../Styles/SkyLineBank.css";
import NotesDrawer from "./NotesDrawer";
import { Button } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { markdownContent } from "./content/markdownContent";
const SkylineBank = () => {
  const markdown = markdownContent.bank;

  return (
    <div>
      <div className="outer-container">
        <div className="inner-container">
          <div className="main-header">
            <div className="first-header">
              <div className="first-header-info-section">
                <div className="navitem">Find a location</div> |
                <div className="navitem">About Us</div>|
                <div className="navitem"> Contact Us</div>
              </div>
              <div className="first-header-search">
                <form className="first-header-search-form">
                  <input
                    type="text"
                    id="header-search-field"
                    name="search"
                    placeholder="How can we help you?"
                  />
                  <button type="submit" className="first-header-search-button">
                    <i className="fa-regular fa-pipe"></i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </button>
                </form>
              </div>

              <div className="first-header-login-signup-buttons">
                <div className="login-button">Log In</div>
                <div className="signup-button">Sign Up</div>
              </div>
            </div>
            <div className="second-header">
              <div className="second-header-logo">
                <img
                  src="./webchat/skyline-bank/images/logo.svg"
                  alt="logo"
                  srcSet=""
                />
              </div>
              <div className="second-header-navigations">
                <div className="navitem">Credit Cards</div>
                <div className="navitem">Loans</div>
                <div className="navitem"> Credit Score</div>
                <div className="navitem"> Financial Tools</div>
              </div>
              <div className="second-header-nav-buttons">
                <a href="/">
                  <Button type="primary" className="home-redirect-button">
                    <HomeOutlined style={{ fontSize: "20px" }} />
                  </Button>
                </a>
                <NotesDrawer markdown={markdown} />
              </div>
            </div>
          </div>
          <div className="banner-containter">
            <div className="banner-title-description">
              <div className="banner-title">
                Get Your Dream Home with Our Home Loan
              </div>
              <div className="banner-description">
                Make your dream of owning a home a reality with our affordable
                and flexible home loan options.
              </div>
              <div className="banner-description">
                Apply online today and let us help you achieve your goal of
                owning a home.
              </div>
            </div>
            <div className="banner-button">
              <button className="banner-read-more-button">Read More...</button>
            </div>
          </div>
          <div className="offers-contacts-container">
            <div className="offers-outer-container">
              <div className="offers-inner-container">
                <div className="offers-header-title">Offers for you!!</div>
                <div className="offers">
                  <div className="offer-1">
                    <div className="offer-title">Savings Account</div>
                    <div className="offer-description">
                      Open Insta Save Account in 4 minutes.
                    </div>
                    <div className="offer-details-button">Open Now</div>
                  </div>
                  <div className="offer-2">
                    <div className="offer-title">Home Loan</div>
                    <div className="offer-description">
                      Digital sanction with benefit of special processing fee.
                    </div>
                    <div className="offer-details-button">Avail Home Loan</div>
                  </div>
                  <div className="offer-3">
                    <div className="offer-title">Credit Card</div>
                    <div className="offer-description">
                      Get the right card for you.
                    </div>
                    <div className="offer-details-button">
                      Get Credit Card Now
                    </div>
                  </div>
                  <div className="offer-4">
                    <div className="offer-title">Personal Loan</div>
                    <div className="offer-description">
                      For all your needs, up to 50L.
                    </div>
                    <div className="offer-details-button">
                      Avail Personal Loan
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contacts-details">
              <h1 className="contacts-details-description">
                For any assistance please call our customer care number
              </h1>
              <h1 className="contacts-details-number">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="#ef0404"
                  className="bi bi-telephone"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                </svg>
                6300 85 80
              </h1>
              <div className="contacts-details-button">Contact Us</div>
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <script
          src="./webchat/skyline-bank/webchat-bank.js"
          type="text/javascript"
        />
      </Helmet>
    </div>
  );
};

export default SkylineBank;
