import React, { useState } from "react";
import { RiSettings3Fill } from "react-icons/ri";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

const UnderDevelopment = (props) => (
  <div className="flex justify-center items-center h-64">
    <p className="text-lg text-gray-500">
      The {props.content} settings is under development
    </p>
  </div>
);

const Settings = () => {
  const [activeTab, setActiveTab] = useState("Profile");
  const data = [
    {
      label: "Profile",
      value: "Profile",
      desc: ``,
    },
    {
      label: "Security",
      value: "Security",
      desc: ``,
    },
    {
      label: "Billing",
      value: "Billing",
      desc: `
      `,
    },
    {
      label: "Notifications",
      value: "Notification",
      desc: ``,
    },
    {
      label: "Theme",
      value: "Theme",
      desc: ``,
    },
  ];
  return (
    <div>
      <div className="flex m-8 justify-between">
        <h1 className="font-bold text-3xl text-[#1C1C1C]">Settings</h1>
        <RiSettings3Fill color="#221F6D" size={35} />
      </div>
      <div>
        <Tabs value={activeTab}>
          <TabsHeader
            className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
            indicatorProps={{
              className:
                "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
            }}
          >
            {data.map(({ label, value }) => (
              <Tab
                key={value}
                value={value}
                onClick={() => setActiveTab(value)}
                className={
                  activeTab === value
                    ? "text-[#343e64] font-bold border-b-2 border-[#343e64] cursor-pointer"
                    : ""
                }
              >
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody>
            {data.map(({ value, desc }) => (
              <TabPanel key={value} value={value}>
                <UnderDevelopment content={value} />
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </div>
    </div>
  );
};

export default Settings;
