import { Card } from "@material-tailwind/react";
import React from "react";
import { BiSolidDashboard } from "react-icons/bi";
import {
  MdAccessTime,
  MdCalendarMonth,
  MdMail,
  MdMoreTime,
  MdOutlineSummarize,
  MdPerson,
} from "react-icons/md";
import { TiDocumentText } from "react-icons/ti";

const Dashboard = () => {
  return (
    <div>
      <div className="flex m-8 justify-between">
        <h1 className="font-bold text-3xl text-[#1C1C1C]">Dashboard</h1>
        <BiSolidDashboard color="#343e64" size={35} />
      </div>
      <div className="flex">
        <div className="flex flex-col gap-4 justify-between m-8 w-8/12">
          <div
            className=" bg-[#fff] p-4 rounded-lg border-0 flex justify-around"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
            }}
          >
            <div className="flex gap-4 items-center">
              <Card className="bg-[#dbcfff] p-5 rounded-md font-semibold ">
                25
              </Card>
              Appoinments
            </div>
            <div className="flex gap-4 items-center">
              <Card className="bg-[#aedff5] p-5 rounded-md font-semibold ">
                05
              </Card>
              Urgent
            </div>
            <div className="flex gap-4 items-center">
              <Card className="bg-[#f8e3e3] p-5 rounded-md font-semibold ">
                03
              </Card>
              Cancelled
            </div>
          </div>
          <div
            className="rounded-lg p-4"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
            }}
          >
            <h4 className="font-bold">Transctipt Bot Usage</h4>
            <div className="flex my-4 gap-4 justify-around">
              <Card
                className="bg-[#fff] flex gap-2 items-center justify-center p-4 rounded-lg"
                style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
              >
                <MdAccessTime size={25} color="#95A4FC" />
                <div className="flex flex-col items-center">
                  <p className="text-center">Total hours of audio</p>
                  <p className="font-semibold">15 hours</p>
                </div>
              </Card>
              <Card
                className="bg-[#fff] flex gap-2 items-center p-4 rounded-lg"
                style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
              >
                <TiDocumentText size={25} color="#95A4FC" />
                <div className="flex flex-col items-center">
                  <p className="text-center">Total Referral Letters</p>
                  <p className="font-semibold">106</p>
                </div>
              </Card>
              <Card
                className="bg-[#fff] flex gap-2 items-center p-4 rounded-lg"
                style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
              >
                <MdOutlineSummarize size={25} color="#95A4FC" />
                <div className="flex flex-col items-center ">
                  <p className="text-center">Total Discharge Summary</p>
                  <p className="font-semibold">125</p>
                </div>
              </Card>
              <Card
                className="bg-[#fff] flex gap-2 items-center p-4 rounded-lg"
                style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
              >
                <MdMoreTime size={25} color="#95A4FC" />
                <div className="flex flex-col items-center">
                  <p className="text-center">Total Time Saved</p>
                  <p className="font-semibold">43.28 hours</p>
                </div>
              </Card>
            </div>
          </div>
          <div
            className="rounded-lg p-4"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
            }}
          >
            <h4 className="font-bold">Costs</h4>
            <div className="flex my-4 justify-around">
              <div className="m-2 flex flex-col items-center gap-2">
                <Card
                  className="bg-[#fff] flex flex-col items-center gap-3 p-5 rounded-md w-fit"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
                  }}
                >
                  <div className="font-semibold flex flex-col gap-4">
                    <p className="text-sm text-[#000000e6]">
                      Total cost for the month
                    </p>
                    <div className="flex items-center gap-1">
                      <p className=" font-semibold text-[#343e64] text-4xl">
                        $7450
                      </p>
                      <p className="text-sm text-[#67BF95]">+9.8%</p>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="m-2  flex flex-col items-center gap-2">
                <Card
                  className="bg-[#fff] flex flex-col items-center gap-3 p-5 rounded-md w-fit"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
                  }}
                >
                  <div className="font-semibold  flex flex-col gap-4">
                    <p className="text-sm text-[#000000e6]">Today's cost</p>
                    <div className="flex items-center gap-1">
                      <p className="font-semibold text-[#343e64] text-4xl">
                        $1285
                      </p>

                      <p className="text-sm text-[#D60E0E]">-3.2%</p>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="m-2 flex flex-col items-center gap-2">
                <Card
                  className="bg-[#fff] flex flex-col items-center gap-3 p-5 rounded-md w-fit"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
                  }}
                >
                  <div className="font-semibold  flex flex-col gap-4">
                    <p className="text-sm text-[#000000e6]">Total Words</p>
                    <div className="flex items-center gap-1">
                      <p className="font-semibold text-[#343e64] text-4xl">
                        9876
                      </p>
                      <p className="text-sm text-[#D60E0E]">-12.4%</p>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 m-8">
          <div className="flex gap-4">
            <Card className="bg-[#dbcfff] flex flex-col gap-2 items-center p-4 rounded-lg border-0 w-28">
              <div className="bg-[#8665f1] w-fit p-2 rounded-full">
                <MdCalendarMonth size={25} color="#fff" />
              </div>
              <div className="flex flex-col text-[#343e64]">
                <p className="text-sm">Available</p>
                <p className="text-xs">
                  <span className="font-semibold">25</span>/60 slots
                </p>
              </div>
            </Card>

            <Card className="bg-[#9cb0ff] flex flex-col gap-2 items-center p-4 rounded-lg border-0 w-28">
              <div className="bg-[#6780e6] w-fit p-2 rounded-full">
                <MdMail size={23} color="#fff" />
              </div>
              <div className="flex flex-col text-[#343e64] items-center">
                <p className="text-sm">Email</p>
                <p className="text-xs font-semibold">13</p>
              </div>
            </Card>
          </div>
          <div className="flex flex-col gap-4">
            <h4 className="font-bold">Today Appoinments</h4>
            <div className="flex flex-col my-4 gap-4">
              <div className="flex items-center gap-2">
                <Card
                  className="bg-[#fff] flex flex-row items-center gap-3 p-5 rounded-md w-full"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
                  }}
                >
                  <div className="bg-[#95A4FC20] flex p-1 rounded-sm w-fit">
                    <MdPerson color="#95A4FC" size={20} />
                  </div>
                  <div className="font-semibold">
                    <p className="text-sm text-[#000000e6]">Yashraj</p>
                    <p className="text-[#505050]">8:00</p>
                  </div>
                </Card>
              </div>
              <div className="flex items-center gap-2">
                <Card
                  className="bg-[#fff] flex flex-row items-center gap-3 p-5 rounded-md w-full"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
                  }}
                >
                  <div className="bg-[#95A4FC20] flex p-1 rounded-sm w-fit">
                    <MdPerson color="#95A4FC" size={20} />
                  </div>
                  <div className="font-semibold">
                    <p className="text-sm text-[#000000e6]">Priyanka</p>
                    <p className="text-[#505050]">8:30</p>
                  </div>
                </Card>
              </div>
              <div className="flex items-center gap-2">
                <Card
                  className="bg-[#fff] flex flex-row items-center gap-3 p-5 rounded-md w-full"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
                  }}
                >
                  <div className="bg-[#95A4FC20] flex p-1 rounded-sm w-fit">
                    <MdPerson color="#95A4FC" size={20} />
                  </div>
                  <div className="font-semibold">
                    <p className="text-sm text-[#000000e6]">Harleen</p>
                    <p className="text-[#505050]">9:00</p>
                  </div>
                </Card>
              </div>
              <div className="flex items-center gap-2">
                <Card
                  className="bg-[#fff] flex flex-row items-center gap-3 p-5 rounded-md w-full"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
                  }}
                >
                  <div className="bg-[#95A4FC20] flex p-1 rounded-sm w-fit">
                    <MdPerson color="#95A4FC" size={20} />
                  </div>
                  <div className="font-semibold">
                    <p className="text-sm text-[#000000e6]">Raghav</p>
                    <p className="text-[#505050]">9:30</p>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
