import React from "react";
import "../Styles/Banners.css"
const Banner = () => {
  return (
    <>
      <div className="partners-demo-banner-outer-container">
        <div className="partners-demo-banner-inner-container">
          <h1 className="partners-demo-banner-banner-title">Partner Centre</h1>
          <p className="partners-demo-banner-banner-tagline">Exclusive for our partners</p>
        </div>
      </div>
    </>
  );
};

export default Banner;
