import React from "react";
import { GoAlertFill } from "react-icons/go";
import { HiDocumentReport } from "react-icons/hi";

const ReportAnalyzer = () => {
  return (
    <div>
      <div className="flex m-8 justify-between">
        <h1 className="font-bold text-3xl text-[#1C1C1C]">Report Analyzer</h1>
        <HiDocumentReport color="#221F6D" size={35} />
      </div>
      <div className="w-full h-[70vh] border-none flex flex-col items-center justify-center gap-2 ">
        <GoAlertFill color="#221F6D" size={100} />
        <h1 className="text-center text-3xl font-semibold text-[#1C1C1C]">
          This module is not activated yet
        </h1>
        <p className="text-center text-lg text-[#1C1C1C]">
          Please contact the administrator for more information
        </p>
      </div>
    </div>
  );
};

export default ReportAnalyzer;
