import React from "react";
import { PiCalendarBlankFill } from "react-icons/pi";

const AppointmentBot = () => {
  return (
    <div>
      <div className="flex m-8 justify-between">
        <h1 className="font-bold text-3xl text-[#1C1C1C]">Appointment Bot</h1>
        <PiCalendarBlankFill color="#221F6D" size={35} />
      </div>
      <iframe
        src="https://inextappointment-gen-ai-bot-as.azurewebsites.net/"
        className="w-full h-[70vh] border-none"
        title="HTML File"
      ></iframe>
    </div>
  );
};

export default AppointmentBot;
