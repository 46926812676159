import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
  names: {
    signIn: "B2C_1_partner-signin",
    forgotPassword: "B2C_1_partner_resetpwd",
    editProfile: "B2C_1_partner_editprofile",
  },
  authorities: {
    signIn: {
      authority:
        "https://inextlabspartners.b2clogin.com/inextlabspartners.onmicrosoft.com/B2C_1_partner-signin",
    },
    forgotPassword: {
      authority:
        "https://inextlabspartners.b2clogin.com/inextlabspartners.onmicrosoft.com/B2C_1_partner_resetpwd",
    },
    editProfile: {
      authority:
        "https://inextlabspartners.b2clogin.com/inextlabspartners.onmicrosoft.com//B2C_1_partner_editprofile",
    },
  },
  authorityDomain: "inextlabspartners.b2clogin.com",
};

export const msalConfig = {
  auth: {
    clientId: "6a19aec0-98a4-4e61-92ed-aefa006773a9", // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: "https://demo.inextlabs.ai/", // this must be https://demo.inextlabs.ai/
    postLogoutRedirectUri: "https://demo.inextlabs.ai/", // this must be https://demo.inextlabs.ai/
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
        }
      },
    },
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [],
};
