import React from 'react'
import { Helmet } from 'react-helmet'

const AvatarVoice = () => {
  return (
    <div>
      <Helmet>
        <script src="./webchat/avatar-voice.js"></script>
      </Helmet>
    </div>
  )
}

export default AvatarVoice
