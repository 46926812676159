import { useEffect, useState } from "react";
import axios from "axios";
import xmlJs from "xml-js";
import { Divider, Drawer } from "antd";
import { MdOutlineFileDownload } from "react-icons/md";

import "../../Styles/Assets.css";
const Assets = () => {
  const [LogosDrawerOpen, setLogosDrawerOpen] = useState(false);
  const [videoDrawerOpen, setVideoDrawerOpen] = useState(false);
  const [leafletsDrawerOpen, setLeafletsDrawerOpen] = useState(false);
  const [decksDrawerOpen, setDecksDrawerOpen] = useState(false);

  const showLogos = () => {
    setLogosDrawerOpen(true);
  };
  const showVideo = () => {
    setVideoDrawerOpen(true);
  };

  const showLeaflets = () => {
    setLeafletsDrawerOpen(true);
  };

  const showDecks = () => {
    setDecksDrawerOpen(true);
  };

  const onCloseLogosDrawer = () => {
    setLogosDrawerOpen(false);
  };
  const onCloseVideoDrawer = () => {
    setVideoDrawerOpen(false);
  };

  const onCloseLeafletsDrawer = () => {
    setLeafletsDrawerOpen(false);
  };

  const onCloseDecksDrawer = () => {
    setDecksDrawerOpen(false);
  };

  return (
    <>
      <div className="assets-outer-container">
        <div className="assets-inner-container">
          <div className="assets-card-contianer">
            <div className="assets-nav-card" onClick={showLogos}>
              <img src="/images/assets/image.svg" alt="" />
              <h4>Logo</h4>
            </div>
            <div className="assets-nav-card" onClick={showVideo}>
              <img src="/images/assets/play.svg" alt="" />
              <h4>Videos</h4>
            </div>

            <div className="assets-nav-card" onClick={showLeaflets}>
              <img src="/images/assets/leaflet.svg" alt="" />
              <h4>Brochures</h4>
            </div>

            <div className="assets-nav-card" onClick={showDecks}>
              <img src="/images/assets/analytic.svg" alt="" />
              <h4>Decks</h4>
            </div>
            <Drawer
              title="Logos"
              placement="left"
              onClose={onCloseLogosDrawer}
              visible={LogosDrawerOpen}
              width={500}
            >
              <Logos />
            </Drawer>
            <Drawer
              title="Videos"
              placement="left"
              onClose={onCloseVideoDrawer}
              visible={videoDrawerOpen}
              width={500}
            >
              <Videos />
            </Drawer>
            <Drawer
              title="Brochures"
              placement="left"
              onClose={onCloseLeafletsDrawer}
              visible={leafletsDrawerOpen}
              width={500}
            >
              <Leaflets />
            </Drawer>
            <Drawer
              title="Decks"
              placement="left"
              onClose={onCloseDecksDrawer}
              visible={decksDrawerOpen}
              width={500}
            >
              <Decks />
            </Drawer>
          </div>
        </div>
      </div>
    </>
  );
};

const Logos = () => {
  const [logosData, setlogosData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://inextlabswebassets.blob.core.windows.net/partners-demo-assets-logos?restype=container&comp=list"
        );
        const xmlData = response.data;
        const jsonData = xmlJs.xml2json(xmlData, { compact: true, spaces: 2 });
        setlogosData(JSON.parse(jsonData).EnumerationResults.Blobs.Blob);
      } catch (error) {
        console.error("Error fetching logos data:", error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleDownload = (url, fileName) => {
    axios({
      url: url,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div>
      {logosData ? (
        <div className="logos-container">
          {logosData.map((logo, index) => (
            <div key={index}>
              <div className="assets-card">
                <div className="assets-image">
                  <img src={logo.Url._text} alt="" style={{ width: "150px" }} />
                </div>
                <div className="assets-details">
                  <p className="assets-name">{logo.Name._text.split(".")[0]}</p>
                  <p className="assets-modified-date">
                    {formatDate(logo.Properties["Last-Modified"]._text)}
                  </p>

                  <a
                    onClick={() =>
                      handleDownload(logo.Url._text, logo.Name._text)
                    }
                  >
                    <MdOutlineFileDownload size={20} />
                    Download
                  </a>
                </div>
              </div>
              <Divider />
            </div>
          ))}
        </div>
      ) : (
        <div class="assets-loader-container">
          <div class="assets-loader"></div>
        </div>
      )}
    </div>
  );
};

const Videos = () => {
  const [videoData, setVideoData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://inextlabswebassets.blob.core.windows.net/partners-demo-assets-video?restype=container&comp=list"
        );
        const xmlData = response.data;
        const jsonData = xmlJs.xml2json(xmlData, { compact: true, spaces: 2 });
        setVideoData(JSON.parse(jsonData).EnumerationResults.Blobs.Blob);
      } catch (error) {
        console.error("Error fetching video data:", error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <div>
      {videoData ? (
        <div className="video-container">
          {videoData.map((video, index) => (
            <div key={index}>
              <div className="assets-card">
                <div className="assets-video-wrapper">
                  <video className="assets-video">
                    <source
                      src={video.Url._text}
                      type={video.Properties["Content-Type"]._text}
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="assets-details">
                  <p className="assets-name">
                    {video.Name._text.split(".")[0]}
                  </p>
                  <p className="assets-modified-date">
                    {formatDate(video.Properties["Last-Modified"]._text)}
                  </p>
                  <a
                    href={video.Url._text}
                    download={video.Name._text.split(".")[0]}
                    target="_blank"
                  >
                    <MdOutlineFileDownload size={20} />
                    Download
                  </a>
                </div>
              </div>
              <Divider />
            </div>
          ))}
        </div>
      ) : (
        <div class="assets-loader-container">
          <div class="assets-loader"></div>
        </div>
      )}
    </div>
  );
};

const Leaflets = () => {
  const [leafletsData, setLeafletsData] = useState(null);
  console.log(leafletsData);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://inextlabswebassets.blob.core.windows.net/partners-demo-assets-brochures?restype=container&comp=list"
        );
        const xmlData = response.data;
        const jsonData = xmlJs.xml2json(xmlData, { compact: true, spaces: 2 });
        setLeafletsData(JSON.parse(jsonData).EnumerationResults.Blobs.Blob);
      } catch (error) {
        console.error("Error fetching leaflets data:", error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleDownload = (url, fileName) => {
    axios({
      url: url,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div>
      {leafletsData ? (
        <div className="leaflets-container">
          {leafletsData.map((leaflet, index) => (
            <div key={index}>
              <div className="assets-card">
                <div className="assets-image">
                  <svg
                    height="80"
                    viewBox="0 0 24 24"
                    width="80"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_5868100"
                  >
                    <g id="_19" data-name="19">
                      <path
                        d="m18 2h-10a1.00028 1.00028 0 0 0 -.832.44531l-2 3a1.00094 1.00094 0 0 0 -.168.55469v15a.99943.99943 0 0 0 1 1h12a.99943.99943 0 0 0 1-1v-18a.99943.99943 0 0 0 -1-1z"
                        fill="#bd1616"
                      ></path>
                      <g fill="#ffe6e6">
                        <path d="m9.52734 5.9541h-.5205a.37509.37509 0 0 0 -.375.375v2.3418a.375.375 0 0 0 .75 0v-.40527h.1455a1.15577 1.15577 0 1 0 0-2.31153zm0 1.56153h-.1455v-.81153h.1455a.40577.40577 0 1 1 0 .81153z"></path>
                        <path d="m14.99316 6.99512h-.92675v-.291h.92675a.375.375 0 0 0 0-.75h-1.30175a.3751.3751 0 0 0 -.375.375v2.34178a.375.375 0 0 0 .75 0v-.92578h.92675a.375.375 0 0 0 0-.75z"></path>
                        <path d="m11.47949 5.9541h-.12988a.3751.3751 0 0 0 -.375.375v2.3418a.3751.3751 0 0 0 .375.375h.12988a1.5459 1.5459 0 0 0 0-3.0918zm.24512 2.30274v-1.51368a.79539.79539 0 0 1 0 1.51368z"></path>
                      </g>
                      <path
                        d="m11 19h-2a.99943.99943 0 0 1 -1-1v-2a1 1 0 0 1 2 0v1h1a1 1 0 0 1 0 2z"
                        fill="#ff2626"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="assets-details">
                  <p className="assets-name">
                    {leaflet.Name._text.split(".")[0]}
                  </p>
                  <p className="assets-modified-date">
                    {formatDate(leaflet.Properties["Last-Modified"]._text)}
                  </p>

                  <a
                    onClick={() =>
                      handleDownload(leaflet.Url._text, leaflet.Name._text)
                    }
                  >
                    <MdOutlineFileDownload size={20} />
                    Download
                  </a>
                </div>
              </div>
              <Divider />
            </div>
          ))}
        </div>
      ) : (
        <div class="assets-loader-container">
          <div class="assets-loader"></div>
        </div>
      )}
    </div>
  );
};

const Decks = () => {
  const [decksData, setDecksData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://inextlabswebassets.blob.core.windows.net/partners-demo-assets-decks?restype=container&comp=list"
        );
        const xmlData = response.data;
        const jsonData = xmlJs.xml2json(xmlData, { compact: true, spaces: 2 });
        setDecksData(JSON.parse(jsonData).EnumerationResults.Blobs.Blob);
      } catch (error) {
        console.error("Error fetching deck data:", error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <div>
      {decksData ? (
        <div className="decks-container">
          {decksData.map((deck, index) => (
            <div key={index}>
              <div className="assets-card">
                <div className="assets-image">
                  <img
                    src="/images/assets/ppt.svg"
                    alt=""
                    style={{ width: "60px" }}
                  />
                </div>
                <div className="assets-details">
                  <p className="assets-name">{deck.Name._text.split(".")[0]}</p>
                  <p className="assets-modified-date">
                    {formatDate(deck.Properties["Last-Modified"]._text)}
                  </p>
                  <a href={deck.Url._text}>
                    <MdOutlineFileDownload size={20} />
                    Download
                  </a>
                </div>
              </div>
              <Divider />
            </div>
          ))}
        </div>
      ) : (
        <div class="assets-loader-container">
          <div class="assets-loader"></div>
        </div>
      )}
    </div>
  );
};

export default Assets;
