import React from "react";
import { MsalProvider } from "@azure/msal-react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

const AppLayout = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MsalProvider>
  );
};

export default AppLayout;
