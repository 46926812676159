import React, { useEffect, useRef, useState } from "react";
import {
  AliwangwangOutlined,
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button } from "antd";

import "./styles/docuassist.css";
import NotesDrawer from "../NotesDrawer";

import { markdownContent } from "../content/markdownContent";

const markdown = markdownContent.bank;
const buttonColor = "#1e4c6f";

function DocuAssist() {
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageHistory, setMessageHistory] = useState([]);

  useEffect(() => {
    // Function to scroll the chat window to the bottom
    const scrollChatToBottom = () => {
      const chatWindow = document.querySelector(".chat-messages");
      if (chatWindow) {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      }
    };

    // Scroll to the bottom whenever messageHistory changes or the component is initially rendered
    scrollChatToBottom();
  }, [messageHistory]);

  const inputRef = useRef(null);

  const handleSearch = async () => {
    setLoading(true);

    inputRef.current.value = "";
    const response = await fetch(
      "https://inextlabs-gpt-vector-services-as.azurewebsites.net/vector-search",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "search-api-key":
            "7R529w8mMMEEr3uC6Q3drfCbhbC27uk3uAL4HiigI9AzSeBeM0l6",
          "openai-api-key": "0a3f454aa46746b5afc360b75d5265ca",
          "conversation-id": "solicitorgeneral123",
          "client-id": "INEXT123",
        },

        body: JSON.stringify({
          search_name: "bots-common-search",
          index_name: "solicitor-general-vector-index-2",
          openai_service_name: "inextlabs-legal-demo-oai",
          openai_embeddings_name: "text-embedding-ada-002",
          question: searchQuery,
          openai_completion_name: "gpt-35-turbo-16k",
          system_prompt:
            "You are an AI assistant for the office of Solicitor General, Philippines.it's important to remember to provide the file URL.\nContent:\n\n",
          temperature: 0,
          max_tokens: 400,
          top_p: 1,
          past_message_count: 5,
          search_response_count: 5,
        }),
      }
    );

    const data = await response.json();
    setLoading(false);
    setMessageHistory((prevHistory) => [
      ...prevHistory,
      { userMessage: searchQuery, aiResponse: data.answer },
    ]);
  };

  return (
    <div className="chat-container">
      <div className="chat-window">
        <div className="chat-header">
          <div className="chat-header-logo">
            <img src="\images\inextlabs-logo-white.png" alt="" width="170" />
          </div>
          <div className="chat-header-title">
            <h1>Philippines eLibrary Data Assistant</h1>
          </div>
          <div className="chat-header-nav-buttons">
            <a href="/">
              <Button
                type="primary"
                style={{ backgroundColor: "#1e4c6f" }}
                className="home-redirect-button"
              >
                <HomeOutlined style={{ fontSize: "20px" }} />
              </Button>
            </a>
            <NotesDrawer markdown={markdown} buttonColor={buttonColor} />
          </div>
        </div>

        <div className="chat-messages">
          {messageHistory.map((message, index) => (
            <div key={index} className="message">
      
                <div className="user">
                  <div className="message-content">{message.userMessage}</div>
                  <div className="user-avatar">
                    <Avatar icon={<UserOutlined />} />
                  </div>
                </div>
              
              <div className="system">
                <div className="system-icon">
                  <Avatar icon={<AliwangwangOutlined />} />
                </div>
                <div className="message-content">{message.aiResponse}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="chat-form">
          <input
            type="text"
            id="message"
            name="message"
            ref={inputRef}
            required
            placeholder="Type your message"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button
            type="submit"
            onClick={handleSearch}
            className={`chat-form-submit-button ${loading ? "hide" : ""}`}
            id="send-button"
          >
            <img src="\images\send (1).svg" alt="" width="25" />
          </button>
          {loading && (
            <div className="loading">
              <div id="loading-spinner" className="custom-loader"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DocuAssist;
