import React, { useEffect, useState } from "react";
import "../Styles/Header.css";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Avatar, Card, Drawer, Space, Typography } from "antd";
import { assetsContent } from "./content/assetsContent";
import Assets from "./assets/Assets";

const Header = () => {
  const SlidesContent = assetsContent.slids;

  const { Text } = Typography;

  const [userData, setUserData] = useState(null);
  const { instance } = useMsal();

  useEffect(() => {
    const sessionKey = sessionStorage.getItem("msal.account.keys");
    const data = JSON.parse(sessionKey);
    const sessionData = data[0];
    const userData = sessionStorage.getItem(sessionData);

    if (userData) {
      const parsedData = JSON.parse(userData).idTokenClaims;
      const { given_name } = parsedData;
      setUserData(given_name);
    }
  }, []);

  const handleLogout = () => {
    sessionStorage.clear();
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="partners-demo-header-outer-container">
        <div className="partners-demo-header-inner-container">
          <div className="partners-demo-header-logo">
            <img src="/images/inextlabs-logo.png" alt="header-logo" />
          </div>
          <div className="partners-demo-header-right">
            <div className="partners-demo-header-right-buttons">
              <div className="partners-demo-header-right-docs">
                <span onClick={showDrawer}> Assets </span>
                <Drawer
                  title="Assets"
                  placement="left"
                  onClose={onClose}
                  open={open}
                  width={500}
                >
                  <Assets />
                </Drawer>
              </div>

              <Link
                to="https://portal.inextlabs.ai"
                target="_blank"
                className="partners-demo-header-right-button"
              >
                Access Portal
              </Link>
              <div
                className="partners-demo-header-logout"
                onClick={handleLogout}
              >
                Log Out
              </div>
            </div>
            <div className="partners-demo-web-header-user-details">
              <div className="partners-demo-web-header-userprofile">
                {/* {userData} */}
                <img src="/images/2150709818.jpg" alt="user-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
